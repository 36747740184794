<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <h4 class="mb-0">
          Twój progres
        </h4>
        <b-card-text class="font-medium-5 mb-0">
<!--          <feather-icon-->
<!--              icon="HelpCircleIcon"-->
<!--              size="21"-->
<!--              class="text-muted cursor-pointer"-->
<!--          />-->
        </b-card-text>
      </b-card-header>

      <!-- apex chart -->
      <vue-apex-charts
          type="radialBar"
          height="245"
          :options="goalOverviewRadialBar.chartOptions"
          :series="goalOverviewRadialBar.series"
      />
      <b-row class="text-center mx-0">
        <b-col
            cols="6"
            class="border-top border-right d-flex align-items-between flex-column py-1"
        >
          <b-card-text class="text-muted mb-0">
            Ukończone treningi
          </b-card-text>
          <h3 class="font-weight-bolder mb-50">
            {{ goal_overview.completed }}
          </h3>
        </b-col>

        <b-col
            cols="6"
            class="border-top d-flex align-items-between flex-column py-1"
        >
          <b-card-text class="text-muted mb-0">
            Pozostałe treningi
          </b-card-text>
          <h3 class="font-weight-bolder mb-0">
            {{ goal_overview.left }}
          </h3>
        </b-col>
      </b-row>
    </b-card>
    <section class="grid-view">
      <b-card
        v-for="item in courses"
        :key="item.id"
        class="ecommerce-card"
        no-body
    >
      <div class="item-img text-center">
        <b-link
            :to="`/moje-kursy/${item.slug}`"
            :style="{ pointerEvents: item.slug ? 'auto' : 'none' }"
        >
          <b-img
              :alt="`${item.title}-${item.id}`"
              fluid
              class="card-img-top"
              :src="`${item.thumbnail}`"
          />
        </b-link>
      </div>

      <!-- Product Details -->
      <b-card-body>
        <div class="item-wrapper">
          <div>
            <h3>{{ item.title }}</h3>
          </div>
        </div>
        <b-card-text class="item-description">
          {{ item.short_description }}
        </b-card-text>
        <b-button v-if="item.subscription" :variant="item.is_canceled > 0 ? 'primary' : 'secondary'" @click="toggleSubscription(item)">
          {{ item.is_canceled > 0 ? 'Odnów subskrypcję' : 'Anuluj subskrypcje' }}
        </b-button>
        <b-card-text class="text-center">
          <h6 class="text-info" v-if="!item.is_expiring">Dostęp wygasa: {{ item.expiry_date }}</h6>
          <h6 class="text-danger" v-else>dostęp wygasa: {{ item.expiry_date }}</h6>
        </b-card-text>
      </b-card-body>

      <!-- extend subscription modal -->
      <b-modal :id="'subscription-types-' + item.id" title="Przedłuż subskrypcję" hide-footer>
        <select v-model="chosenSubscriptionExtension" class="form-control my-2">
          <option v-for="subscription in item.subscription_types" :value="subscription.value">
            {{subscription.name}} - {{subscription.price}} zł
          </option>
        </select>
        <b-button variant="primary" class="mb-2" @click="handleCartActionClick(item, chosenSubscriptionExtension)">
          Przedłuż subskrypcję
        </b-button>
      </b-modal>
      <b-modal :id="'extend-course-types-' + item.id" title="Przedłuż dostęp do Kursu" hide-footer>
        <h3>{{ item.title }}</h3>
        <select v-model="chosenExtensionOption" class="form-control my-2">
          <option selected  disabled value="">Wybierz wariant przedłużenia</option>
          <option v-for="option in item.extend_options" :value="option.value">
            {{option.name}} - {{option.price}} zł
          </option>
        </select>
        <b-button variant="primary" class="mb-2" @click="handleCartActionClick(item, chosenExtensionOption)">
          Przedłuż dostęp
        </b-button>
      </b-modal>
      <!-- Product Actions -->
      <div class="item-options text-center">
        <b-button
            variant="primary"
            tag="a"
            class="btn-cart"
            :class="{'disabled' : !item.slug}"
            :to="`/moje-kursy/${item.slug}`"
            :style="{ pointerEvents: item.slug ? 'auto' : 'none' }"
        >
          <span>Przejdź do kursu</span>
        </b-button>
        <b-button
            variant="danger"
            tag="a"
            class="btn-cart"
            @click="extend(item)"
            v-if="item.is_expiring"
        >
          <span>Przedłuż dostęp do kursu</span>
        </b-button>
      </div>
    </b-card>
    </section>
  </div>

</template>

<script>
import {
  BCard, BCardHeader, BRow, BCol, BCardText, BCardBody, BLink, BImg, BListGroup, BListGroupItem, BButton,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import {BvModalMsgBoxShortcutMethod} from "bootstrap-vue/src/components/modal";

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BRow,
    BCardText,
    BCol,
    BCardBody,
    BLink,
    BImg,
    BButton,
  },
  data() {
    return {
      courses: [],
      goal_overview: {
        completed: 0,
        left: 0,
      },
      goalOverviewRadialBar: {
        series: [0],
        chartOptions: {
          chart: {
            sparkline: {
              enabled: true,
            },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
          colors: ['#d23d24'],
          plotOptions: {
            radialBar: {
              offsetY: -10,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '77%',
              },
              track: {
                background: '#ebe9f1',
                strokeWidth: '50%',
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  color: '#5e5873',
                  fontSize: '2.86rem',
                  fontWeight: '600',
                },
              },
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: ['#d23d24'],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            lineCap: 'round',
          },
          grid: {
            padding: {
              bottom: 30,
            },
          },
        },
      },
    }
  },
  created() {
    this.checkProgress();
    this.fetchCourses();
    this.checkFillQuiz();
  },
  methods: {
    fetchCourses() {
      this.$http.get(`${this.$store.state.apiDomain}/api/my/courses`).then(response => {
        this.courses = response.data.courses
      })
    },
    async checkFillQuiz() {
      this.$http.get(`${this.$store.state.apiDomain}/api/individual-courses/check`).then(response => {
        if(Object.keys(response.data).length > 0 && response.data[0] !== null) {
          let router = this.$router;

           this.$bvModal.msgBoxConfirm('Wypełnij formularz na kurs indywidualny', {
             'noCloseOnBackdrop': true,
             'noCloseOnEsc' : true,
              'okTitle': 'Wypełnić',
              'cancelTitle': 'Anulować',
           }, response.data, router).then(function(value) {
             if (value === true) {
               router.push({ name: 'starter-poll', params: { id: response.data[0] }});
             }
           }).catch(error => {})
        }
      })
    },
    async checkProgress() {
      this.$http.get(`${this.$store.state.apiDomain}/api/my/check-progress`).then(response => {
        if (response.data.percent) {
          this.goalOverviewRadialBar.series = [response.data.percent];
          this.goal_overview.completed = response.data.done;
          this.goal_overview.left = response.data.left;
        }
      })
    },
    async toggleSubscription(item) {
      this.$http.get(`${this.$store.state.apiDomain}/api/my/course-active/${item.id}`).then(response => {
        if(response){
          item.is_canceled = response.data.is_canceled;
        }
      })
    },
  },
}
</script>

<style lang="scss">
.grid-view {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2rem;
  &.wishlist-items {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .ecommerce-card {
    overflow: hidden;

    // Item Image
    .item-img {
      padding-top: 0.5rem;
      min-height: 15.85rem;
      display: flex;
      align-items: center;
    }

    // Item content wrapper
    .item-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }

    // Hiding list view items
    .shipping,
    .item-company,
    .item-options .item-price {
      display: none;
    }

    .item-options {
      display: flex;
      flex-wrap: wrap;

      .btn-cart,
      .btn-wishlist {
        flex-grow: 1;
        border-radius: 0;
      }
    }

    .item-name {
      margin-top: 0.75rem;
    }

    // Item Description
    .item-description {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-top: 0.2rem;
    }

    .card-body {
      padding: 1rem;
    }
  }
}
@media all and (max-width: 768px){
  .grid-view {
    grid-template-columns: 1fr;
  }
}
</style>
